








































































































import Vue from "vue";
import { defineComponent, ref } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { convertApiItemToCardUi } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/utils/convertApiItemToUi";
import { modalElements, historicalNoteElements } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/modalElements";
import { get } from "lodash";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/viewTitle";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { IERCProtocolsCardElement } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/types/ERCProtocolsCardElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { IDocument } from "@monorepo/inventory/src/views/EadView/types/IDocument";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { fields } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/fields";
import { v4 as uuid } from "uuid";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { Route } from "vue-router";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import useFormLinkByAuthoritiesModal from "@monorepo/utils/src/composables/useFormLinkByAuthoritiesModal";
import { convertSaveCardObject } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/utils/convertSaveCardObject";
import TxtViewer from "@monorepo/inventory/src/components/TxtViewer.vue";
import TestImgPreview from "@monorepo/inventory/src/components/TestImgPreview.vue";

export default defineComponent({
  name: "InfoModal",
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    ToggleDocumentView,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    FormInputElement,
    ExportBtn,
    CardModalChapter,
    TxtViewer,
    TestImgPreview,
    CaseTableTree: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewCaseTableTree" */
        "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/CaseTableTree.vue"
      ),
    ComissionWrapper: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewCaseTableTree" */
        "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/ComissionWrapper.vue"
      ),
    HistoricalNotesTree: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewCaseTableTree" */
        "@monorepo/inventory/src/views/HistoricalNotesView/components/HistoricalNotesTree.vue"
      ),
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      ModalType,
      isSaveLoading: false,
      key: uuid(),
      modalElements,
      historicalNoteElements,
      element: {} as ReturnType<typeof convertApiItemToCardUi>,
      viewUniqKey,
      openedPanels: [0, 1, 2],
      currentDocumentView: 0,
      isShowDocuments: false,
      openedDocument: "",
      documents: {},
      cardMode: CardMode,
      section: Sections.INVENTORY_PROJECT,
      fields,
      isLoading: false,
      casesList: {},
      isShowPdf: false,
      file: null as any,
      fileId: null as number | null | string,
      noteFiles: [] as any[],
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("ExpertComissionView", ["data", "openedId"]),
    isShowCommission(): boolean {
      return !!this.element.expertCommissionDto || isAuthorityExist(this.user, authorities.EXPERT_COMMISSION_CREATION);
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isNeedAgreeUpon(): boolean {
      return false;
    },
    isNeedApprove(): boolean {
      return true;
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.INVENTORY_EXPORT);
    },
    isShowContentTooltip(): unknown {
      return (key: string) => {
        switch (key) {
          case this.fields.KIND:
          case this.fields.CATEGORY:
            return true;
          default:
            return false;
        }
      };
    },
    getItemElement(): unknown {
      return (key: string) => {
        switch (key) {
          default:
            return get(this.element, key);
        }
      };
    },
    modalFieldTitle(): (field: string) => IModalElementType {
      return (field: string) => {
        return this.modalElements.find((item: IModalElementType) => item.value === field) || ({} as IModalElementType);
      };
    },
    isShowProtocolExport(): boolean {
      return this.element.commissionStatus?.code === "APPROVED" && isAuthorityExist(this.user, authorities.EXPERT_COMMISSION_EXPORT);
    },
    isPrefaceExists(): boolean {
      return !!(this.element?.isPrefaceExists && this.element?.prefaceFileGuid);
    },
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getInventoryElement: (val: string) => Promise<IERCProtocolsCardElement>;
          element: ReturnType<typeof convertApiItemToCardUi>;
          getDocumentList: (id: number) => Promise<IDocument>;
          isShowDocuments: boolean;
          data: IERCProtocolsCardElement[];
          $route: Route;
          isLoading: boolean;
          isShowAnimation: boolean;
          formValues: Record<string, string>;
          changedFormValues: Record<string, string>;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getInventoryElement(value)
            .then((data: IERCProtocolsCardElement) => {
              this.element = convertApiItemToCardUi(data || {});
              this.formValues = {
                comment: this.element?.comment || "",
              };
              this.changedFormValues = { ...this.formValues };
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("ExpertComissionView", ["changeOpenedId", "getDocumentList", "getInventoryElement", "saveCard", "getScreenshot", "getTxtFile"]),
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    handleCreateCommision() {
      this.isLoading = true;
      this.getInventoryElement(this.element.id)
        .then((data: IERCProtocolsCardElement) => {
          this.element = convertApiItemToCardUi(data || {});
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    clickElementCb(header: { isLink: boolean; value: string }) {
      switch (header.value) {
        case fields.OIK_SHORT_NAME: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/dictionaries/oik", {
            name: this.element.oikShortName as string,
            isOpenModal: "1",
          });
          break;
        }

        case fields.FUND_NUMBER: {
          (
            this as unknown as {
              moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void;
            }
          ).moveByAuthorities(header, "/dictionaries/fund", {
            number: this.element.fundNumber as string,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
    closeModal() {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
    downloadPdf() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    openCardPdf() {
      if (this.element?.id) {
        this.openNewWindow(`${getFullPath(QUERY_PATH.GET_EXPERT_COMMISSION_PDF_LIST)}/${this.element.id}`);
      }
    },
    openProtocolPdf() {
      if (this.element?.id) {
        this.openNewWindow({ url: `${getFullPath(QUERY_PATH.GET_EXPERT_COMMISSION_PDF_PROTOCOL)}/${this.element.id}`, type: "POST" });
      }
    },
    openProtocolPreface() {
      if (this.element?.id) {
        this.openNewWindow(getFullPath(`${QUERY_PATH.GET_FILE_FROM_FILE_STORAGE}?uuid=${this.element?.prefaceFileGuid}&origin=false`));
      }
    },
    getResultSaveData(data: Record<string, string>) {
      return {
        comment: data.comment || "",
      };
    },
    async onSave(data: Record<string, string>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = {
          ...this.getResultSaveData(data),
          openedId: this.openedId,
        };

        this.isSaveLoading = true;
        const isSaved = await this.saveCard(resultData).finally(() => {
          this.isSaveLoading = false;
        });
        if (isSaved) {
          this.changedFormValues = this.getResultSaveData(resultData);
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.closeModal();
        }
      } else {
        return;
      }
    },
    changeContracts(
      this: {
        $formulate: {
          setValues: (key: string, object: Record<string, string>) => void;
        };
      },
      contracts: Record<string, string>[],
      values: Record<string, string>
    ) {
      (
        this.$formulate as {
          setValues: (key: string, object: Record<string, string>) => void;
        }
      ).setValues("erc-protocols-info-modal", Object.assign(values, { contracts }));
    },
    onFileNameClick({ item }: any) {
      this.file = item;
      this.fileId = item.id;
      this.noteFiles = item.historicalNote?.fileList ?? [];

      this.isShowPdf = true;
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({});
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "ExpertComissionView", Sections.INVENTORY_PROJECT, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "ExpertComissionView");
    const { resultModalElements, moveByAuthorities } = useFormLinkByAuthoritiesModal(root, modalElements);

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
      resultModalElements,
      moveByAuthorities,
    };
  },
});
